<template>
  <div class="relative z-10 invisible" aria-labelledby="slide-over-title" role="dialog" aria-modal="true" id="slideover-container">
    <!-- Background backdrop, show/hide based on slide-over state. -->
    <div class="fixed inset-0"></div>

    <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div id="slideover" class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 duration-300 ease-out transition-all translate-x-full">
            <div class="pointer-events-auto w-screen max-w-md ">
              <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-green-700 px-4 py-6 sm:px-6">
                    <div class="flex items-center justify-between">
                      <h2 class="text-base font-semibold leading-6 text-white" id="slide-over-title">Add Defect</h2>
                      <div class="ml-3 flex h-7 items-center">
                        <button
                            type="button"
                            class="relative rounded-md bg-green-700 text-green-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            @click="toggleModal(false)"
                        >
                          <span class="absolute -inset-2.5"></span>
                          <span class="sr-only">Close panel</span>
                          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col justify-between">
                    <div class="divide-y divide-gray-200 px-4 sm:px-6">
                      <div class="space-y-6 pb-5 pt-6">

                        <div class="relative" v-for="(category, index) in $parent.categ" :key="index">
                          <h6 class="mb-0">
                            <button
                                @click="expand(category.id, 0, 'category')"
                                type="button"
                                class="relative flex items-center w-full p-2 font-semibold text-left transition-all ease-in border-b border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group text-dark-500"
                            >
                              <span>{{ category.name }}</span>
                              <i class="absolute right-0 pt-1 text-xs group-open:opacity-0 fa fa-plus" :id="'expandCategory' + category.id"></i>
                              <i class="absolute right-0 pt-1 text-xs opacity-0 fa fa-minus group-open:opacity-100"></i>
                            </button>
                          </h6>
                          <div
                              :id="category.id"
                              class="overflow-hidden transition-all duration-300 ease-in-out invisible m-4 h-0"
                          >
                            <div v-for="(type, indexType) in category.types" :key="indexType">
                              <h6 class="mb-0">
                                <button
                                    @click="expand(type.name + '_' + type.id, type.id, 'type')"
                                    type="button"
                                    class="relative flex items-center w-full p-2 font-semibold text-left transition-all ease-in border-b border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group text-dark-500"
                                >
                                  <span>{{ type.name }}</span>
                                  <i class="absolute right-0 pt-1 text-xs fa fa-plus group-open:opacity-0" :id="'expandType' + type.id"></i>
                                  <i class="absolute right-0 pt-1 text-xs opacity-0 fa fa-minus group-open:opacity-100"></i>
                                </button>
                              </h6>
                              <div
                                  :id="type.name + '_' + type.id"
                                  class="overflow-hidden transition-all duration-300 ease-in-out invisible h-0"
                              >
                                  <div class="mt-2">
                                    <div class="flex w-12 items-center text-sm font-medium">
                                      <label for="upload-button">
                                        <input :id="'defect_'+ type.id" accept="image/*" capture="environment" name="defect" type="file" hidden @change="renderDefectImage(type.id)">
                                        <button
                                            type="button"
                                            class="bg-white-600 mx-5 text-gray-800 hover:bg-white-500 border-0"
                                            @click="chooseDefectImageFile(type.id)"
                                        >
                                          <span class="fa-stack fa-2x">
                                            <img :id="'image_' + type.id" class="max-h-85 max-w-70 object-contain invisible h-0" src="#"/>
                                            <i class="fa-solid fa-camera" :id="'cameraIcon_' + type.id"></i>
                                          </span>
                                        </button>
                                      </label>
                                      <div>
                                        <img src="img/top-view-truck.png" alt="No image" class="previewImage" width="150" @click="previewImage(type.id, category.id)"/>
                                        <small class="italic text-red-600 text-xs overlay-text">Tap the image to set defect area</small>
                                      </div>
                                    </div>
                                    <div class="mt-2 mb-5 mx-5 my-5">
                                      <textarea
                                          rows="4"
                                          name="description"
                                          :id="'description_' + type.id"
                                          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          placeholder="Defect description"
                                      />

                                      <ErrorAlert v-if="$store.state.isError"/>
                                    </div>

                                    <div class="flex flex-shrink-0 justify-end px-4 py-4">
                                      <button
                                          @click="saveTemp(type)"
                                          type="button"
                                          class="ml-4 inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
    </div>
  </div>

  <ImagePreview v-if="showPreview" :isCreate="true"/>
</template>

<script>

import ImagePreview from "@/components/Common/ImagePreview.vue";
import ErrorAlert from "@/components/Common/ErrorAlert.vue";
import heic2any from "heic2any";

export default {
  name: 'DefectPanel',
  components: {ErrorAlert, ImagePreview},
  // props: ['categories'],
  data() {
    return {
      isShowDefectImage: false,
      categories: [],
      description: '',
      documentFile: '',
      defects: [],
      showPreview: false,
      selectedDefectType: '',
      selectedDefectCategory: '',
      selectedDefect: '',
      selectedDefectArea: '',
      xyCoordinates: [],
      isCategoryExpanded: false,
      isTypeExpanded: false,
      defectImage: '',
      limitDefectArea: 1,
    }
  },
  methods: {
    expand(type, typeId, where) {
      console.log(type)
      document.getElementById(type).classList.toggle('invisible')
      document.getElementById(type).classList.toggle('h-0')

      if (where === 'category') {
        document.getElementById('expandCategory' + type).classList.toggle('fa-plus')
        document.getElementById('expandCategory' + type).classList.toggle('fa-minus')
      } else {
        document.getElementById('expandType' + typeId).classList.toggle('fa-plus')
        document.getElementById('expandType' + typeId).classList.toggle('fa-minus')
      }
      this.selectedDefect = this.getDefectTypeById(typeId)

      if (typeof this.selectedDefect !== 'undefined') {
        document.getElementById('description_' + typeId).value = this.selectedDefect.description
        if (this.selectedDefect.document_file !== null) {
          let imageHandler = document.getElementById('image_' + typeId)
          imageHandler.src = 'data:image/png;base64,' + this.selectedDefect.document_file
          imageHandler.classList.remove('invisible')
          imageHandler.classList.remove('h-0')

          let cameraIcon  = document.getElementById('cameraIcon_' + typeId)
          cameraIcon.classList.add('invisible')

          // localStorage.setItem('imageDefect'+ typeId, this.selectedDefect.document_file);
        }
      }
    },
    toggleModal(removeDefects) {
      document.getElementById('slideover-container').classList.toggle('invisible')
      document.getElementById('slideover').classList.toggle('translate-x-full')

      if (removeDefects) {
        localStorage.removeItem('defects')
      }
    },
    renderDefectImage(defectId) {
      const [file] = document.getElementById('defect_'+ defectId).files
      const t = this
      if (file) {
        this.isShowDefectImage = true
        setTimeout(async function () {
          let convertedBlob = file;
          console.log(file.type)
          if (file.type === 'image/heif' || file.type === 'image/heic') {
            convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
          }

          let selectedImage = document.getElementById('image_' + defectId)
          selectedImage.src = URL.createObjectURL(convertedBlob)
          selectedImage.classList.remove('invisible')
          selectedImage.classList.remove('h-0')
          selectedImage.classList.add('defectImage')

          let cameraIcon  = document.getElementById('cameraIcon_' + defectId)
          cameraIcon.classList.add('invisible')


          const reader = new FileReader()
          reader.readAsDataURL(convertedBlob);
          reader.onload = function(event) {
            const base64String = event.target.result.split(',')[1]; // Extract the Base64 part
            console.log(base64String) // Logs the Base64 encoded string
            // localStorage.setItem('imageDefect'+ defectId, base64String);
            t.saveDefectImageToLocal(defectId, base64String)
            // Now you can use the base64String as needed
          };
        }, 1000)
      }
    },
    saveDefectImageToLocal(type, stringFile) {
      this.indexedDB = window.indexedDB
      if (!this.indexedDB) {
        console.log("IndexedDB could not be found in this browser.");
      }
      const request = indexedDB.open("DefectImages", 1);

      request.onerror = function (event) {
        console.error("An error occurred with IndexedDB");
        console.error(event);
      };

      request.onupgradeneeded = function () {
        const db = request.result;
        db.createObjectStore("images", {keyPath: "type"});
      };

      request.onsuccess = function () {
        console.log("Database opened successfully");
        const db = request.result;
        const transaction = db.transaction("images", "readwrite");
        const store = transaction.objectStore("images");

        store.put({type: type, file: stringFile});

        transaction.oncomplete = function () {
          db.close();
        };
      };
    },
    getDefectImageFromLocal(id)
    {
      const t = this
      this.indexedDB = window.indexedDB
      if (!this.indexedDB) {
        console.log("IndexedDB could not be found in this browser.");
      }

      const request = indexedDB.open("DefectImages", 1);

      request.onerror = function (event) {
        console.error("An error occurred with IndexedDB");
        console.error(event);
      };

      request.onsuccess = function () {
        console.log("Database opened successfully");
        const db = request.result
        if (db.objectStoreNames.length === 0) {
          console.log('The database has no object stores.');
          db.close()
        } else {
          const transaction = db.transaction("images", "readwrite")
          const store = transaction.objectStore("images")
          const idQuery = store.get(id)

          idQuery.onsuccess = function () {
            // console.log('idQuery', idQuery.result)
            if (typeof idQuery.result !== 'undefined') {
              t.defectImage = idQuery.result.file
            }
          }
          transaction.oncomplete = function () {
            db.close();
          };
          console.log('The database has the following object stores:', db.objectStoreNames);
        }
      }
    },
    getDefectTypeById(typeId) {
      // const t = this
      let defectList = this.$parent.addedDefects // JSON.parse(localStorage.getItem('defects'))
      if (defectList !== null) {
        let result = defectList.filter(function (chain) {
          return chain.dvir_defect_type_id === typeId;
        });

        return result[0];
      }
    },
    saveTemp(type)
    {
      this.getDefectImageFromLocal(type.id)
      if (document.getElementById('description_' + type.id).value === '') {
        this.$store.state.isError = true
        this.$store.state.errorMsg = 'Defect description is required.'
        return
      }
      const t = this

      setTimeout(function () {
        console.log('defect image from index db: ' + t.defectImage)

        if (t.$parent.addedDefects != null) {
          for (let [i, defect] of t.$parent.addedDefects.entries()) {
            if (defect.dvir_defect_type_id === type.id) {
              t.$parent.addedDefects.splice(i, 1);
            }
          }
        }
        t.$parent.addedDefects.push({
          dvir_defect_type_id: type.id,
          description: document.getElementById('description_' + type.id).value,
          document_file: t.defectImage,
          typeName: type.name,
          defectArea: localStorage.getItem('xyCoordinates_'+type.id)
        })

        // console.log(JSON.stringify('DEFECTS: ' + JSON.stringify(t.$parent.addedDefects)))
        t.toggleModal()
      }, 1000)

    },
    chooseDefectImageFile(defectId) {
      document.getElementById('defect_' + defectId).click()
    },
    previewImage(typeId, categoryId) {
      this.showPreview = true
      this.selectedDefectType = typeId
      this.selectedDefectCategory = categoryId
    }
  },
  created() {
    console.log('created')
    const t = this
    t.categories = t.$parent.categ
  },
  mounted() {
    //
  }
}
</script>
<style>
.defectImage {
  max-height: 50px !important;
  height: 50px !important;
  width: 70px !important;
}
.previewImage {
  max-width: none !important;
}
.overlay-text {
  z-index: 9999;
  position: relative;
  top: -100px;
  font-size: xx-small !important;
}
</style>