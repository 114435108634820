import axios from 'axios';
import { API_URL } from '@/config/constants';

export default {
    namespaced: true,
    state: {
        userData: {},
        username: '',
        password: '',
        errorMsg: '',
        mobileNumber: '',
        isError: false,
        isLogin: 'false',
        otp1: '',
        otp2: '',
        otp3: '',
        otp4: ''
    },
    getters: {
        data: state => state.userData,
    },
    actions: {
        login({commit, state, rootState}) {
            let data = {
                mobileNumber: state.mobileNumber,
                otp: state.otp // state.otp1.toString() + state.otp2.toString() + state.otp3.toString() + state.otp4.toString()
            }
            const config = {
                headers: {
                    // 'Authorization': `Bearer `+ state.apiToken,
                    'Content-Type': 'multipart/form-data'
                }
            };

            rootState.isProcessing = true

            setTimeout(function () {
                axios.post(API_URL + 'login', data, config)
                    .then(function (response) {
                        if (response.data.status) {
                            state.apiToken = response.data.auth_token
                            state.isLogin = true
                            localStorage.setItem('apiToken', response.data.auth_token)
                            localStorage.setItem('userData', JSON.stringify(response.data.user))
                            localStorage.removeItem('isOtp')
                            commit('setUserData', response.data.user)
                            localStorage.setItem('isLogin', state.isLogin)
                            window.location.href = '/dashboard'
                        } else {
                            rootState.errorMsg = response.data.message
                            rootState.isError = true
                            state.isLogin = false
                            rootState.isSuccess = false

                            rootState.isOtp = false
                            localStorage.removeItem('isOtp')
                            localStorage.removeItem('username')
                            rootState.successMsg = ''
                        }

                        console.log(response.data.user);
                        rootState.isProcessing = false
                        state.otp1 = ''
                        state.otp2 = ''
                        state.otp3 = ''
                        state.otp4 = ''

                    }).catch((error) => {
                        console.log(error)
                        rootState.isError = true
                        rootState.isSuccess = false
                        if (typeof error.response !== 'undefined') {
                            console.log(error.response.data.message)
                            rootState.errorMsg = error.response.data.message
                        }
                        rootState.errorMsg = error.message
                        rootState.isProcessing = false
                        state.otp1 = ''
                        state.otp2 = ''
                        state.otp3 = ''
                        state.otp4 = ''
                        rootState.isOtp = false
                        localStorage.removeItem('isOtp')
                        localStorage.removeItem('username')
                        this.$store.state.successMsg = ''
                });
            }, 1000)

        },
        sendOtp({state, rootState}) {
            let data = {
                mobileNumber: state.mobileNumber,
            }
            const config = {
                headers: {
                    // 'Authorization': `Bearer `+ state.apiToken,
                    'Content-Type': 'multipart/form-data'
                }
            };

            rootState.isProcessing = true

            setTimeout(function () {
                axios.post(API_URL + 'otp', data, config)
                    .then(function (response) {
                        if (response.data.status) {
                            rootState.successMsg = response.data.message
                            rootState.isError = false
                            rootState.isSuccess = true
                            rootState.isOtp = true
                            localStorage.setItem('isOtp', true)
                            localStorage.setItem('username', state.username)
                            localStorage.setItem('mobileNumber', state.mobileNumber)
                            // this.$store.state.successMsg = 'Enter the 4 digit code that was emailed to you'
                        } else {
                            rootState.errorMsg = response.data.message
                            rootState.isError = true
                            rootState.isSuccess = false
                            state.isLogin = false
                            rootState.isOtp = false
                            localStorage.removeItem('isOtp')
                            localStorage.removeItem('username')
                            localStorage.removeItem('mobileNumber')
                            rootState.successMsg = ''
                        }

                        console.log(response.data.user);
                        rootState.isProcessing = false

                    }).catch((error) => {
                    console.log(error)
                    rootState.isError = true
                    rootState.isSuccess = false
                    if (typeof error.response !== 'undefined') {
                        console.log(error.response.data.message)
                        rootState.errorMsg = error.response.data.message
                    }
                    rootState.errorMsg = error.message
                    rootState.isProcessing = false
                    rootState.isOtp = false
                    localStorage.removeItem('isOtp')
                    localStorage.removeItem('username')
                    localStorage.removeItem('mobileNumber')
                    rootState.successMsg = ''
                });
            }, 1000)
        }
    },
    mutations: {
        setUserData(state, data) {
            // console.log(data)
            state.userData = data
        }
    }
}