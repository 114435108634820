<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <LogoPanel />

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <div class="max-w-md mx-auto bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
        <div class="rounded-md bg-yellow-50 p-4">
          <div class="">
            <div class="text-center">
              <svg class="h-20 w-12 text-yellow-400 text-center justify-center inline-block" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clip-rule="evenodd" />
              </svg>
              <h2 class="text-1xl font-bold mb-10">Important Update: Install the New CopperFleet Driver App!</h2>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-yellow-800">
                Hello Drivers, <br /><br />We have an important update for you! 🚀 We’ve launched a new version of our app to give you an even better experience. To make the switch, please follow these simple steps:
              </h3>

              <div class="mt-2 text-sm text-yellow-700">
                <ul role="list" class="list-disc space-y-1 pl-5">
                  <li>Uninstall the Old App:</li>
                  <ul role="list" class="list-disc space-y-1 pl-5">
                    <li>On your device, locate the old Cregger Driver app.</li>
                    <li>Tap and hold the app icon until a menu appears.</li>
                    <li>Select Uninstall or Remove App.</li>
                  </ul>
                  <li>Install the New App:</li>
                  <ul role="list" class="list-disc space-y-1 pl-5">
                    <li>Open your browser and go to driver.copperfleet.com.</li>
                    <li>Once the page loads, you should see a prompt to install the app.</li>
                    <li>Follow the on-screen instructions to add it to your home screen or apps.</li>
                    <li>Or click the link below for step by step instructions on how to install the app.</li>
                  </ul>
                </ul>
              </div>
              <br />

              <h3 class="text-sm font-medium text-yellow-800">
                <a href="https://docs.google.com/document/d/1XKKqS-DsuI3D2X9y_QHvnv_-3WyYF__GYSAuCbmUnFE" target="_blank">https://docs.google.com/document/d/1XKKqS-DsuI3D2X9y_QHvnv_-3WyYF__GYSAuCbmUnFE</a>
              </h3>
              <br />
              <h3 class="text-sm font-medium text-yellow-800">
                That’s it! You’re all set with the latest version. 🎉
              </h3>
              <br />
              <h3 class="text-sm font-medium text-yellow-800">
                If you have any questions or need help, don’t hesitate to reach out to our support team or please call Jacob at 720-213-6443.
              </h3>
              <br />
              <h3 class="text-sm font-medium text-yellow-800">
                Thank you for being part of CopperFleet!
                <br /><br />
                Best regards,<br />
                The CopperFleet Team
              </h3>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import LogoPanel from "@/components/Common/Logo.vue";

export default {
  name: 'NotificationPanel',
  data() {
    return {
      //
    }
  },
  components: {
    LogoPanel,
  },
  methods: {
    clearPWACache() {
      // Check if the browser supports the Cache API
      if ('caches' in window) {
        caches.keys().then(cacheNames => {
          cacheNames.forEach(cacheName => {
            caches.delete(cacheName).then(() => {
              console.log(`Cache ${cacheName} deleted successfully.`);
            }).catch(err => {
              console.error(`Error deleting cache ${cacheName}:`, err);
            });
          });
        });
      }
    }
  },
  props: {
    msg: String
  },
  mounted() {
    this.clearPWACache();

    if (indexedDB.databases) {
      indexedDB.databases().then(databases => {
        databases.forEach(db => {
          const dbName = db.name;
          if (dbName) {
            let deleteRequest = indexedDB.deleteDatabase(dbName);

            deleteRequest.onsuccess = function() {
              console.log(`Database ${dbName} deleted successfully.`);
            };

            deleteRequest.onerror = function(event) {
              console.error(`Error deleting database ${dbName}:`, event);
            };

            deleteRequest.onblocked = function(event) {
              console.warn(`Delete request blocked for database ${dbName}.`, event);
            };
          }
        });
      }).catch(error => {
        console.error('Error listing databases:', error);
      });
    } else {
      console.warn('indexedDB.databases() is not supported in this browser.');
    }

    localStorage.clear()
  }
}
</script>