<template>
  <PageTitle />
  <SelectedVehiclePanel />

  <SuccessAlert v-if="$store.state.isSuccess"/>
  <ErrorAlert v-if="$store.state.isError"/>
  <ViewDvirPanel />
</template>

<script>

import ViewDvirPanel from "@/components/Dvir/View.vue";
import PageTitle from "@/components/Common/PageTitle.vue";
import ErrorAlert from "@/components/Common/ErrorAlert.vue";
import SelectedVehiclePanel from "@/components/Vehicle/Selected.vue";
import SuccessAlert from "@/components/Common/SuccessAlert.vue";

export default {
  data() {
    return {
      selectedDvir: {},
    }
  },
  name: 'ViewDvirView',
  components: {
    SuccessAlert, SelectedVehiclePanel, ErrorAlert,
    PageTitle,
    ViewDvirPanel
  },
  created() {
    this.selectedDvir = JSON.parse(localStorage.getItem('selectedDvir'))
  },
  mounted() {
    // Set page title
    this.$store.dispatch('setPageTittle', 'View DVIR', '/vehicle-details')
    this.$store.dispatch('setParentPage', '/vehicle-details')
  }
}
</script>