/* eslint-disable no-console */

import { register } from 'register-service-worker'
import Swal from 'sweetalert2';

// alert('A new version of the CopperFleet Driver App is available! Please uninstall this version and visit https://driver.copperfleet.com to install the latest one.');

if ('caches' in window) {
  caches.keys().then(cacheNames => {
    cacheNames.forEach(cacheName => {
      caches.delete(cacheName).then(() => {
        console.log(`Cache ${cacheName} deleted successfully.`);
      }).catch(err => {
        console.error(`Error deleting cache ${cacheName}:`, err);
      });
    });
  });
}

localStorage.clear()

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
      Swal.fire({
        title: 'No Internet Connection',
        text: 'App is running in offline mode.',
        icon: 'warning',
      });
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
