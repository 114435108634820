<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <LogoPanel />

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

      <ErrorAlert v-if="$store.state.isError"/>
      <SuccessAlert v-if="$store.state.isSuccess"/>

      <form class="space-y-2" action="#" method="POST" v-if="!$store.state.isOtp">
          <div class="relative mt-0 rounded-md shadow-sm">
            <div
                class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-sm"
            >

              <span class="flex select-none items-center pl-3 pr-3 text-gray-500 sm:text-sm bg-gray-100"><i class="fa-solid fa-mobile-alt"></i></span>
              <input
                  type="text"
                  name="mobileNumber"
                  id="mobileNumber"
                  v-model="$store.state.login.mobileNumber"
                  class="block flex-1 border-0 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="Enter mobile number (+1123456789)"
                  :disabled="$store.state.forceUpdate"
              >
            </div>
<!--            <input
                type="email"
                autocapitalize="off"
                autocomplete="email"
                name="text"
                id="username"
                v-model="$store.state.login.username"
                class="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Username"
            />-->
          </div>
          <div class="text-center py-10">
            <button
                type="button"
                @click="otp"
                class="rounded-full bg-white px-4 py-2.5 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 justify-items-center w-3/4"
                :disabled="$store.state.forceUpdate"
              >
              Next
            </button>
          </div>
      </form>

      <div class="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow" v-if="$store.state.isOtp">
        <header class="mb-8">
          <h1 class="text-2xl font-bold mb-1">OTP Verification</h1>
          <p class="text-[15px] text-slate-500">Enter the 4 digit code that was emailed to you</p>
        </header>
        <form id="otp-form">
          <div class="flex items-center justify-center gap-3">
            <input class="w-28 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 number-input"
                   type="text"
                   id="otp"
                   maxlength="4"
                   inputmode="numeric"
                   pattern="\d{4}"
                   v-on:keyup="validateOtp()"
                   v-model="$store.state.login.otp" />
          </div>
        </form>
        <div class="text-sm text-slate-500 mt-4">Didn't receive code? <a class="font-medium text-indigo-500 hover:text-indigo-600" href="#0" @click="otp">Resend</a></div>
      </div>

    </div>
  </div>
</template>

<script>
import ErrorAlert from "@/components/Common/ErrorAlert.vue";
import LogoPanel from "@/components/Common/Logo.vue";
import SuccessAlert from "@/components/Common/SuccessAlert.vue";

export default {
  name: 'LoginPanel',
  data() {
    return {
      isOtp: false,
    }
  },
  components: {
    SuccessAlert,
    LogoPanel,
    ErrorAlert
  },
  methods: {
    otp() {
      this.$store.state.login.otp = ''
      let mobileNumber = this.$store.state.login.mobileNumber || ''
      if (mobileNumber && !mobileNumber.startsWith('+')) {
        mobileNumber = '+' + mobileNumber
      }
      console.log(mobileNumber)
      this.$store.state.login.mobileNumber = mobileNumber

      this.$store.dispatch('login/sendOtp')
    },
    login() {
      this.$store.dispatch('login/login')
    },
    validateOtp()
    {
      this.$store.state.login.otp = this.$store.state.login.otp.replace(/\D/g, '').substring(0, 4);
      if (this.$store.state.login.otp.length === 4) {
        this.login()
      }
    },
    validate() {
      let t = this;
      const inputs = document.querySelectorAll('.number-input');

      inputs.forEach((input, index) => {
        input.addEventListener('input', function() {
          if (this.value.length === 1 && /^[0-9]$/.test(this.value)) {
            // Move to the next input if there's a next input
            if (index < inputs.length - 1) {
              inputs[index + 1].focus();
            } else {
              // Optionally, you can submit the form or do something else
              console.log('All inputs filled');
              t.login()
            }
          }
        });

        input.addEventListener('keydown', function(e) {
          if (e.key === 'Backspace' && this.value === '') {
            // Move to the previous input if there's a previous input
            if (index > 0) {
              inputs[index - 1].focus();
            }
          }
        });
      });
    }
  },
  props: {
    msg: String
  },
  mounted() {
    if ('caches' in window) {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName).then(() => {
            console.log(`Cache ${cacheName} deleted successfully.`);
          }).catch(err => {
            console.error(`Error deleting cache ${cacheName}:`, err);
          });
        });
      });
    }

    localStorage.clear()
    console.log('IS_OTP: ' + localStorage.getItem('isOtp'))
    if (localStorage.getItem('isOtp')) {
      this.$store.state.isOtp = true
      this.$store.state.login.username = localStorage.getItem('username')
      this.$store.state.isSuccess = true
      this.$store.state.successMsg = 'Enter the 4 digit code that was emailed to you'
    }
  }
}
</script>