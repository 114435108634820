<template>
  <PageTitle />
  <SelectedVehiclePanel v-if="!isCertifyPage"/>

  <SuccessAlert v-if="$store.state.isSuccess"/>
  <ErrorAlert v-if="$store.state.isError"/>

  <CreateDvirPanel />
  <DefectPanel />

</template>

<script>

import CreateDvirPanel from "@/components/Dvir/Create.vue";
import PageTitle from "@/components/Common/PageTitle.vue";
import ErrorAlert from "@/components/Common/ErrorAlert.vue";
import SelectedVehiclePanel from "@/components/Vehicle/Selected.vue";
import SuccessAlert from "@/components/Common/SuccessAlert.vue";
import DefectPanel from "@/components/Dvir/Defect.vue";

export default {
  name: 'CreateDvirView',
  data() {
    return {
      isCertifyPage: false,
      indexedDB: '',
      front: '',
      back: '',
      driver: '',
      passenger: '',
      gps: 0,
      odometer: '',
      postTrip: '',
      preTrip: '',
      inspectionType: '',
      safeToDrive: '',
      addedDefects: [],
      categ: [],
      newDvir: {},
      startedAt: '',
      submittedAt: '',
      dvirDefectList: [],
    }
  },
  components: {
    DefectPanel,
    SuccessAlert, SelectedVehiclePanel, ErrorAlert,
    PageTitle,
    CreateDvirPanel
  },
  methods: {
    addDefect() {
      document.getElementById('slideover-container').classList.toggle('invisible');
      document.getElementById('slideover').classList.toggle('translate-x-full');
    },
    saveImageToLocal(type, stringFile) {
      // 1
      this.indexedDB = window.indexedDB

      if (!this.indexedDB) {
        console.log("IndexedDB could not be found in this browser.");
      }

      console.log('Opening index db')

      // 2
      const request = indexedDB.open("DvirImage", 1);

      request.onerror = function (event) {
        console.error("An error occurred with IndexedDB");
        console.error(event);
      };

      request.onupgradeneeded = function () {
        const db = request.result;
        db.createObjectStore("images", { keyPath: "type" });
        // store.createIndex("images_type", ["type"], { unique: false });
      };

      request.onsuccess = function () {
        console.log("Database opened successfully");

        const db = request.result;

        // 1
        const transaction = db.transaction("images", "readwrite");

        //2
        const store = transaction.objectStore("images");

        //3
        store.put({ type: type, file: stringFile });

        // 6
        transaction.oncomplete = function () {
          db.close();
        };
      };
    },
    getImagesFromLocal(id)
    {
      const t = this
      this.indexedDB = window.indexedDB

      if (!this.indexedDB) {
        console.log("IndexedDB could not be found in this browser.")
      }

      const request = indexedDB.open("DvirImage", 1)

      request.onerror = function (event) {
        console.error("An error occurred with IndexedDB")
        console.error(event)
      };

      request.onsuccess = function () {
        const db = request.result
        if (db.objectStoreNames.length === 0) {
          console.log('The database has no object stores.');
          db.close()
        } else {
          const transaction = db.transaction(["images"], "readwrite")
          const store = transaction.objectStore("images")
          const idQuery = store.get(id)

          transaction.onerror = function(event) {
            console.log('Transaction error:', event.target.error);
          };

          idQuery.onsuccess = function () {
            console.log('TEST: ' + idQuery.result, idQuery)
            if (id === 'front') {
              if (typeof idQuery.result !== 'undefined') {
                t.front = idQuery.result.file
              }
            }

            if (id === 'driver') {
              if (typeof idQuery.result !== 'undefined') {
                t.driver = idQuery.result.file
              }
            }

            if (id === 'back') {
              if (typeof idQuery.result !== 'undefined') {
                t.back = idQuery.result.file
              }
            }

            if (id === 'passenger') {
              if (typeof idQuery.result !== 'undefined') {
                t.passenger = idQuery.result.file
              }
            }

          }
        }
      }
    },
    submitDvir() {
      const t = this
      t.$store.state.isProcessing = true
      t.getImagesFromLocal('front')
      t.getImagesFromLocal('driver')
      t.getImagesFromLocal('back')
      t.getImagesFromLocal('passenger')
      t.submittedAt = this.formatDate(new Date());

      setTimeout(function () {
        t.newDvir = {
          vehicle_id: t.$store.state.vehicle.selectedVehicle.id,
          user_id: t.$store.state.login.userData.id,
          front_image_file: t.front,
          driver_image_file: t.driver,
          back_image_file: t.back,
          passenger_image_file: t.passenger,
          gps: t.gps,
          odometer: t.odometer,
          inspection_type: t.inspectionType,
          safe_to_drive: (t.safeToDrive ? 1 : 0),
          defects: JSON.stringify(t.addedDefects),
          started_at: t.startedAt,
          submitted_at: t.submittedAt
        }

        // console.log('NEW_DVIR: ' + JSON.stringify(t.newDvir))
        t.$store.dispatch('dvir/store', t.newDvir)
      }, 1000)
    },
    formatDate(date) {
      var hours = date.getHours()
      var minutes = date.getMinutes()
      var ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours ? hours : 12 // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes
      var strTime = hours + ':' + minutes + ampm
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + strTime
    }
  },
  created() {
    localStorage.removeItem('defects')
    this.categ = JSON.parse(localStorage.getItem('defectCategories'))
  },
  mounted() {
    // Set page title
    this.$store.dispatch('setPageTittle', 'Create DVIR', '/dashboard')
    localStorage.removeItem('defects')

    this.startedAt = this.formatDate(new Date());
    if (localStorage.getItem('started_at') !== null) {
      this.startedAt = localStorage.getItem('started_at')
    } else {
      localStorage.setItem('started_at', this.startedAt)
    }

    var data = JSON.parse(localStorage.getItem('dvirList')).data;
    let t = this
    // Group by vehicle_id and get dvir_defects
    data.reduce((acc, report) => {
      // Filter dvir_defects that don't have a resolution_date
      const unresolvedDefects = report.dvir_defects.filter(defect => !defect.resolution_date);

      // Map unresolved defects to the desired object structure, only if not already in addedDefects
      const mappedDefects = unresolvedDefects.map(defect => {
        const mappedDefect = {
          dvir_defect_type_id: defect.dvir_defect_type_id,
          description: defect.description,
          document_file: defect.document_file,
          typeName: defect.defect_type.name, // Extract the name of the defect type
          defectArea: JSON.stringify(defect.defect_areas) // Parse defect areas if available
        };

        // Check if the defect already exists in t.$parent.addedDefects
        const exists = t.addedDefects.some(existingDefect =>
            existingDefect.dvir_defect_type_id === mappedDefect.dvir_defect_type_id &&
            existingDefect.description === mappedDefect.description
        );

        // If it doesn't exist, return it to be added
        return exists ? null : mappedDefect;
      }).filter(defect => defect !== null); // Remove null values (already existing defects)

      // Push only non-existing mapped defects to the addedDefects array
      if (mappedDefects.length > 0) {
        // t.addedDefects.push(...mappedDefects);
      }

      return acc;
    }, {});

    // localStorage.setItem('defects', JSON.stringify(this.addedDefects))
  }
}
</script>