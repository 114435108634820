<template>
  <nav class="flex items-center justify-between flex-wrap bg-white-500 p-4">
    <div class="flex items-center flex-shrink-0 text-gray-800 mr-6">
      <span class="inline-block h-14 w-14 overflow-hidden rounded-full bg-gray-300 mr-4">
        <img class="h-full w-full text-gray-800" :src="($store.state.login.userData.image === '' ? 'img/no-photo.png' : $store.state.login.userData.image )"/>
      </span>
      <span class="font-semibold text-xl tracking-tight mr-3">{{ $store.state.login.userData.first_name }} {{ $store.state.login.userData.last_name}}</span>
    </div>
      <div>
        <a href="#" @click="logout" class="inline-block text-sm px-4 py-2 leading-none rounded hover:border-transparent hover:text-teal-500 hover:bg-white mt-1 lg:mt-0 text-green-800 text-xl">
          <i class="fa-solid fa-right-from-bracket"></i>
        </a>
      </div>
  </nav>

  <div class="rounded-md bg-yellow-50 p-4" v-if="$store.state.forceUpdate">
    <div class="">
      <div class="text-center">
        <svg class="h-20 w-12 text-yellow-400 text-center justify-center inline-block" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
          <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clip-rule="evenodd" />
        </svg>
        <h2 class="text-1xl font-bold mb-10">A new version of the CopperFleet Driver App is available! Please LOGOUT or UNINSTALL this version and visit https://driver.copperfleet.com to install the latest one.</h2>

        <h2><a href="#" @click="logout" class="text-1xl font-bold mb-10 text-red-900">Logout</a></h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderPanel',
  data() {
    return {
      'userData': JSON.parse(localStorage.getItem('userData'))
    }
  },
  methods: {
    logout() {
      this.$store.state.login.isLogin = false
      this.$store.state.login.apiToken = ''
      localStorage.clear()
      window.location.href = '/'
    }
  },
  mounted() {
    this.$store.state.login.userData = JSON.parse(localStorage.getItem('userData'))
  }
}
</script>